import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Have you gotten started building on the Internet Computer and are interested in writing some automated tests for your code? This is a quick guide on how to get started, using the Hello World starter app.`}</p>
    <p>{`An example project with all the code used here is available at `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/sample-canister-e2e"
      }}>{`https://github.com/krpeacock/sample-canister-e2e`}</a>{`.`}</p>
    <h2 {...{
      "id": "setting-up"
    }}><a parentName="h2" {...{
        "href": "#setting-up"
      }}>{`Setting Up`}</a></h2>
    <p>{`First, start with a `}<inlineCode parentName="p">{`dfx new hello`}</inlineCode>{` project. Remove the `}<inlineCode parentName="p">{`hello_assets`}</inlineCode>{` folder and then remove the canister config in dfx.json. Once you've cleaned up the boilerplate, your config should look like this: `}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"canisters"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"hello"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"main"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"src/hello/main.mo"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"type"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"motoko"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"version"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <p>{`Next, install `}<inlineCode parentName="p">{`vitest`}</inlineCode>{` and `}<inlineCode parentName="p">{`isomorphic-fetch`}</inlineCode>{`. Note: You can use Jest instead, but you'll need to do a little more setup.`}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{`npm install --save-dev vitest isomorphic-fetch
`}</span></code></pre>
    <p>{`Add `}<inlineCode parentName="p">{`"test": "vitest"`}</inlineCode>{` to your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` scripts.`}</p>
    <h3 {...{
      "id": "actor-setup"
    }}><a parentName="h3" {...{
        "href": "#actor-setup"
      }}>{`Actor setup`}</a></h3>
    <p>{`Create a folder for your tests. I place mine in `}<inlineCode parentName="p">{`<project-root>/src/e2e`}</inlineCode>{`. Inside of `}<inlineCode parentName="p">{`e2e`}</inlineCode>{`, create a
utility to create your agent using the generated declarations, named `}<inlineCode parentName="p">{`actor.js`}</inlineCode>{`. Note: I'm mixing use of JS and TS files here because there are a couple annoying TS warnings with these imports that aren't worth fixing.`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// actor.js`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Actor`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`HttpAgent`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/agent"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`fetch`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"isomorphic-fetch"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`canisterIds`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`".dfx/local/canister_ids.json"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` idlFactory `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"../declarations/hello/hello.did.js"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`createActor`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`canisterId`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`agentOptions `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fetchRootKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Creates an actor with using the candid interface and the HttpAgent`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`idlFactory`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    canisterId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`options`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?.`}</span>{`actorOptions`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` helloCanister `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` canisterIds`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`hello`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`local`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` hello `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`createActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`helloCanister`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  agentOptions`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` host`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"http://127.0.0.1:8000"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` fetch `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Since we are testing locally, we always will fetch the root key.`}</p>
    </blockquote>
    <p>{`This setup file handles reading canister IDs from their JSON, importing IDL from the declarations, creating a default actor and configuring it with a fetch polyfill (not necessary in Node 16+) and local host.`}</p>
    <h2 {...{
      "id": "writing-tests"
    }}><a parentName="h2" {...{
        "href": "#writing-tests"
      }}>{`Writing tests`}</a></h2>
    <p>{`Now, create a file for your tests. `}<inlineCode parentName="p">{`hello.test.ts`}</inlineCode>{`. First, we'll set up the basics, importing our testing methods, `}<inlineCode parentName="p">{`agent-js`}</inlineCode>{` imports, and our actor utilities.`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// hello.test.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` expect`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` test `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"vitest"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Actor`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`CanisterStatus`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`HttpAgent`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/agent"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Principal`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/principal"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` helloCanister`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` hello `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"./actor"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h3 {...{
      "id": "calling-hello-world"
    }}><a parentName="h3" {...{
        "href": "#calling-hello-world"
      }}>{`Calling Hello World`}</a></h3>
    <p>{`Finally, we can test our canister! This method is fundamentally pretty simple, but let's go through the exercise anyway. The `}<inlineCode parentName="p">{`test`}</inlineCode>{` method accepts two arguments - a test name, and a function. Inside of the test, we'll run through some steps, and then use the `}<inlineCode parentName="p">{`expect`}</inlineCode>{` util to check results against expected results. See `}<a parentName="p" {...{
        "href": "https://vitest.dev/"
      }}>{`vitest docs`}</a>{` for more info.`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"should handle a basic greeting"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` result1 `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` hello`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`greet`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"test"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`expect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`result1`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toBe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Hello, test!"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Spin up your canister, and then you can run `}<inlineCode parentName="p">{`npm test`}</inlineCode>{` (or `}<a parentName="p" {...{
        "href": "https://bun.sh/"
      }}>{`bun`}</a>{` test!) to run your new test. `}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{`dfx start --background --clean
dfx deploy
dfx generate
npm test
`}</span></code></pre>
    <p>{`You should see a success message like this in your terminal, while `}<inlineCode parentName="p">{`vitest`}</inlineCode>{` waits for new changes to your source code.`}</p>
    <pre {...{
      "className": "language-shell-session"
    }}><code parentName="pre" {...{
        "className": "language-shell-session"
      }}><span parentName="code" {...{
          "className": "token output"
        }}>{` ✓ src/e2e/hello.test.ts (1)

Test Files  1 passed (1)
     Tests  1 passed (1)
      Time  13ms


 PASS  Waiting for file changes...
       press h to show help, press q to quit
`}</span></code></pre>
    <h3 {...{
      "id": "testing-canisterstatus"
    }}><a parentName="h3" {...{
        "href": "#testing-canisterstatus"
      }}>{`Testing CanisterStatus`}</a></h3>
    <p>{`For a slightly more complex test as reference, let's add a test for canister metadata using the new CanisterStatus API. `}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token function"
        }}>{`test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Should contain a candid interface"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` agent `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`agentOf`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`hello`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`as`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`HttpAgent`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Principal`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`helloCanister`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` canisterStatus `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`CanisterStatus`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`request`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    canisterId`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` id`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    agent`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    paths`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"time"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"controllers"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"candid"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`expect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterStatus`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"time"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toBeTruthy`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`expect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`Array`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`isArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterStatus`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"controllers"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toBeTruthy`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`expect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`canisterStatus`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"candid"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toMatchInlineSnapshot`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token template-string"
        }}><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "token string"
          }}>{`
    "service : {
      greet: (text) -> (text) query;
    }
    "
  `}</span><span parentName="span" {...{
            "className": "token template-punctuation string"
          }}>{`\``}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h2 {...{
      "id": "repeatable-identities"
    }}><a parentName="h2" {...{
        "href": "#repeatable-identities"
      }}>{`Repeatable identities`}</a></h2>
    <p>{`It is often useful to use an identity that will remain consistent across runs of your e2e tests. Here is a script using `}<inlineCode parentName="p">{`bip39`}</inlineCode>{` and the industry standard "12 peacocks" test phrase.`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`//identity.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="span" {...{
            "className": "token maybe-class-name"
          }}>{`Secp256k1KeyIdentity`}</span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"@dfinity/identity"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`hdkey`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"hdkey"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}>{`bip39`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"bip39"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Completely insecure seed phrase. Do not use for any purpose other than testing.`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Resolves to "wnkwv-wdqb5-7wlzr-azfpw-5e5n5-dyxrf-uug7x-qxb55-mkmpa-5jqik-tqe"`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` seed `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{`
  `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"peacock peacock peacock peacock peacock peacock peacock peacock peacock peacock peacock peacock"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`identityFromSeed`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`phrase`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` seed `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` bip39`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`mnemonicToSeed`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`phrase`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` root `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` hdkey`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromMasterSeed`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` addrnode `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` root`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`derive`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"m/44'/223'/0'/0/0"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Secp256k1KeyIdentity`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`fromSecretKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`addrnode`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`privateKey`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`export`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` identity `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`identityFromSeed`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`seed`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <p>{`This script will reproduce an identical Principal to the same seed phrase imported through `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/quill"
      }}>{`Quill`}</a>{` into DFX. `}</p>
    <p>{`You can verify the reproducibility by adding a test for it - identity.test.ts`}</p>
    <pre {...{
      "className": "language-ts"
    }}><code parentName="pre" {...{
        "className": "language-ts"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// identity.test.ts`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` expect`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` test `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"vitest"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token imports"
        }}><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` identity `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`}`}</span></span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"./identity"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token function"
        }}>{`test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"the identity should be the same"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` principal `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword control-flow"
        }}>{`await`}</span>{` identity`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`getPrincipal`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`expect`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`principal`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toString`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`toMatchInlineSnapshot`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`
    `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'"wnkwv-wdqb5-7wlzr-azfpw-5e5n5-dyxrf-uug7x-qxb55-mkmpa-5jqik-tqe"'`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h2 {...{
      "id": "finally-continuous-integration"
    }}><a parentName="h2" {...{
        "href": "#finally-continuous-integration"
      }}>{`Finally, Continuous Integration`}</a></h2>
    <p>{`This part is boring and I don't want to explain how to configure Github in depth. If you want to add checks to your PR's, do the following.`}</p>
    <ol>
      <li parentName="ol">{`add a CI script to your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` scripts.`}</li>
    </ol>
    <p>{`We'll do this with`}</p>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token property"
        }}>{`"ci"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"vitest run"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
`}<span parentName="code" {...{
          "className": "token property"
        }}>{`"preci"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"dfx stop; dfx start --background --clean; dfx deploy; dfx generate"`}</span>{`
`}</code></pre>
    <p>{`This way, it automatically sets up dfx and runs the tests a single time, reporting the results.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Add a GitHub workflow config.`}</li>
    </ol>
    <p>{`Specifically, this one. Create a `}<inlineCode parentName="p">{`.github`}</inlineCode>{` folder and inside it, create a `}<inlineCode parentName="p">{`workflows`}</inlineCode>{` folder. Add `}<inlineCode parentName="p">{`e2e.yml`}</inlineCode>{` with these contents:`}</p>
    <pre {...{
      "className": "language-yml"
    }}><code parentName="pre" {...{
        "className": "language-yml"
      }}><span parentName="code" {...{
          "className": "token key atrule"
        }}>{`name`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` End to End

`}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`on`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
  `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`pull_request`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
    `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`types`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` opened
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` reopened
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` edited
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` synchronize

`}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`jobs`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
  `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`test`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
    `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`runs-on`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` $`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` matrix.os `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
    `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`strategy`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
      `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`fail-fast`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean important"
        }}>{`false`}</span>{`
      `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`matrix`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`os`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span>{`ubuntu`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`20.04`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{`
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`ghc`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'8.8.4'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span>{`
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`spec`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'0.16.1'`}</span>{`
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`node`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
          `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`16`}</span>{`

    `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`steps`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`uses`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` actions/checkout@v2
      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`name`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` Use Node.js $`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` matrix.node `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`uses`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` actions/setup`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`node@v1
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`with`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
          `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`node-version`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` $`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` matrix.node `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`run`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` npm install

      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`run`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` echo y `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`|`}</span>{` sh `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`ci "$(curl `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{`fsSL https`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`//sdk.dfinity.org/install.sh)"

      `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`-`}</span>{` `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`run`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` npm run ci
        `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`env`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{`
          `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`CI`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean important"
        }}>{`true`}</span>{`
          `}<span parentName="code" {...{
          "className": "token key atrule"
        }}>{`REPLICA_PORT`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`8000`}</span>{`

`}</code></pre>
    <p>{`And there you go! You should now have automated end to end tests running on your canister for every pull request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      